import React from "react";
import { Link } from "gatsby";
import Typography from '@material-ui/core/Typography';

import Blog from "../blog-config.json";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BlogPostHeader from "../components/BlogPostHeader";

const ProgPoW = () => {
    console.log({Blog})
    const post = Blog.posts["considerations-of-progpow"];
    return (
        <Layout>
            <SEO title={post.title} />
            <BlogPostHeader title={post.title} subTitle={post.subTitle} imgSrc={post.featuredImage}/>
            {post.body.map(item => 
                <Typography className={"white-monospace"} variant="h6" gutterBottom={true} component="p">
                    {item}
                </Typography>
            )}
            <Link to="/">
                <Typography className={"white-monospace"} variant="h6" gutterBottom={true} component="h2">
                    Return Home
                </Typography>
            </Link>
        </Layout>
    )
}

export default ProgPoW